import { authFetch } from "./responseHandler";

export class TgBotSetupApiService {
    static baseUrl = process.env.REACT_APP_API_URL;


    static async GetCurrentTracks() {
        const accessToken = localStorage.getItem('bb_token');
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const result = await authFetch(this.baseUrl + `TgBotSetup/GetCurrentTracks`, { headers });
        if (result.status !== 200)
            throw new Error("GetCurrentTracks failed");

        return await result.json();
    }

    static async CreateTrack(coinAddress, groupId) {
        const accessToken = localStorage.getItem('bb_token');
        const headers = {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
        };
        const body = JSON.stringify({ coinAddress, groupId });

        const result = await authFetch(this.baseUrl + "TgBotSetup/CreateTrack", {
            method: 'POST',
            headers: headers,
            body: body
        });

        if (result.status !== 200)
            throw new Error("CreateTrack failed");

        let json = await result.json();

        return json;
    }



    static async RemoveTrack(id) {
        const accessToken = localStorage.getItem('bb_token');
        const headers = {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
        };

        const result = await authFetch(this.baseUrl + `TgBotSetup/RemoveTrack?trackId=${id}`, {
            method: 'POST',
            headers: headers
        });

        if (result.status !== 200)
            throw new Error("RemoveTrack failed");

        let json = await result.json();

        return json;
    }

}