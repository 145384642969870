const bb_recent_ton_wallets_key = "bb_recent_ton_wallets";

export class LocalStorageHelper {
    static NoImageAsset() {
        return "https://cache.tonapi.io/imgproxy/cOMlJuViiVXDCkAghnyNj7plX8pAZ9pv3WhklvebpTY/rs:fill:200:200:1/g:no/aHR0cHM6Ly9yYXcuZ2l0aHVidXNlcmNvbnRlbnQuY29tL3RvbmtlZXBlci9vcGVudG9uYXBpL21hc3Rlci9wa2cvcmVmZXJlbmNlcy9tZWRpYS90b2tlbl9wbGFjZWhvbGRlci5wbmc.webp";
    }

    static AddTonAddressToRecent(address) {
        try{
            let data = localStorage.getItem(bb_recent_ton_wallets_key);

            if (data) {
                let parsedData = JSON.parse(data);
                if (!parsedData.some(x => x.address == address)) {
                    parsedData.push({ address: address, date: new Date() });
                    localStorage.setItem(bb_recent_ton_wallets_key, JSON.stringify(parsedData));
                }
                else {
                    parsedData.find(x => x.address == address).date = new Date();
                    localStorage.setItem(bb_recent_ton_wallets_key, JSON.stringify(parsedData));
                }
            }
            else {
                localStorage.setItem(bb_recent_ton_wallets_key, JSON.stringify([{ address: address, date: new Date() }]));
            }
        }
        catch(error){
        }
    }

    static ClearRecentTonAddresses() {
        localStorage.removeItem(bb_recent_ton_wallets_key);
    }

    static GetRecentTonAddresses() {
        let data = localStorage.getItem(bb_recent_ton_wallets_key);

        if (!data)
            return [];

        let parsedData = JSON.parse(data);

        let sortedData = parsedData.sort(function(a,b){
            return new Date(b.date) - new Date(a.date);
          });

        return sortedData;
    }

}