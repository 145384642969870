export class MainApiService {
    static baseUrl = process.env.REACT_APP_API_URL;

    static async InitLogin(initData) {
        let request = await fetch(this.baseUrl + `Auth/InitLogin`, {
            method: "POST",
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify({initData: initData})
        });

        return request;
    }

    static async AppLoading(initData) {
        const accessToken = localStorage.getItem('bb_token');
        if(!accessToken)
            return;
        
        const headers = { 'Authorization': 'Bearer ' + accessToken, 'content-type': 'application/json' };

        let request = await fetch(this.baseUrl + `Auth/AppLoading`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({initData: initData})
        });

        return request;
    }

}