import { authFetch } from "./responseHandler";

export class ProfileApiService {
    static baseUrl = process.env.REACT_APP_API_URL;

    static GetProfileHookUrl() {
        return this.baseUrl + "Profile/Info";
    }
    
    static async GetBurnInfo(coinAddress) {
        const accessToken = localStorage.getItem('bb_token');
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const result = await authFetch(this.baseUrl + `Profile/BurnInfo?coinAddress=${coinAddress}`, { headers });

        if(result.status !== 200)
            return null;
        
        return await result.json()
    }

    static async GetAirdropInfo() {
        const accessToken = localStorage.getItem('bb_token');
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const result = await authFetch(this.baseUrl + `Profile/AirdropInfo`, { headers });

        if(result.status !== 200)
            return null;
        
        return await result.json()
    }

    static async GetAllWalletsBalanceInfo() {
        const accessToken = localStorage.getItem('bb_token');
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const result = await authFetch(this.baseUrl + `Profile/GetAllWalletsBalance`, { headers });
        
        return result.json()
    }

    static async GetSeedPhrase() {
        const accessToken = localStorage.getItem('bb_token');
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const result = await authFetch(this.baseUrl + "Profile/Seed", { headers });
        
        return result.json()
    }


    static async UpdateProfile(slippage, mevProtection) {
        const accessToken = localStorage.getItem('bb_token');
        const headers = { 
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
        };
        const body = JSON.stringify({ slippage, mevProtection });
        
        const result = await authFetch(this.baseUrl + "Profile/Update", {
            method: 'POST',
            headers: headers,
            body: body
        });

        if(result.status !== 200)
            throw new Error("UpdateProfile failed");  
             
        return result;
    }

}