export class TonCenterApiService {
    static async GetJettonInfo(jettonAddress) {
        const result = await fetch(`https://toncenter.com/api/v3/jetton/masters?address=${jettonAddress}&limit=128&offset=0`);
        if (result.status === 200) {
            let json =  await result.json()
            if(!json.jetton_masters || json.jetton_masters.length == 0)
                return null;

            let jettonContent = json.jetton_masters[0].jetton_content;
            
            return {...jettonContent, address: json.jetton_masters[0].address, decimals: jettonContent.decimals ? jettonContent.decimals : 9};
        }

        return null;
    }
}