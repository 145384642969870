import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { Onboarding } from "../components/onboarding";
import { MainApiService } from "../services/main.api.service";
import React, { useState, useEffect, useRef } from 'react';
import { StaticDataHelper } from "../helpers/static-data-helper";
import { useTonAddress } from '@tonconnect/ui-react';

const Layout = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const userFriendlyAddress = useTonAddress();

    const tonConnectRef = useRef();

    const tonConnect = () => {
        if (tonConnectRef.current) {
            tonConnectRef.current(); // Вызов функции из main.js
        }
    };

    const initData = window?.Telegram?.WebApp?.initData;

    useEffect(() => {
        if (initData && initData !== '' && window.location.search) {
            const query = window.location.search;

            switch (true) {
                case query.includes("pgbotsetup"):
                    navigate("/tg-bot-setup");
                    break;
                case query.includes("pgmnsettings"):
                    navigate("/profile");
                    break;
                case query.includes("pgjtpg"):
                    const parameters = query.split(StaticDataHelper.StartParamSeparator());
                    const caParam = parameters.find(param => param.startsWith('jtca'));
                    if (caParam) {
                        const coinAddress = caParam.replace('jtca', '');

                        navigate("/jetton/" + coinAddress);
                    }
                    break;
                default:
                    break;
            }
        }
    }, [navigate]);

    if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
        if (!initData || initData === '') {
            return <>
                <div style={{ marginTop: "50vh", textAlign: "center" }}>
                    <div className="h4">Telegram only Mini App</div>
                    <div className="h4">Site here</div>
                    <a href="https://wendizel.com" className="h4">https://wendizel.com</a>
                </div>
            </>;
        }
    }


    window.Telegram.WebApp.expand();

    var BackButton = window.Telegram.WebApp.BackButton;
    BackButton.onClick(function () {
        BackButton.hide();
    });

    window.Telegram.WebApp.onEvent('backButtonClicked', function () {
        navigate("/");
    });


    return (
        <>
            <Onboarding></Onboarding>
            <div className="header">
                <div className="header_left">
                    <div className="header_logo"><img width={24} src="/images/money_with_wings.png" alt="logo" /></div>
                    <div className="header_title">BuyBag<span className="header_title_second">Beta</span></div>
                </div>
                <div className="header_right">
                    {location.pathname === "/" && !userFriendlyAddress && <div onClick={tonConnect} className="header_airdrop blue_style">Connect Wallet</div>}
                    <Link to="/profile"><div className="header_settings"><img width={24} src="/images/settings.png" alt="settings" /></div></Link>
                    {/* {location.pathname === "/" && <Link to="/profile"><div className="header_settings"><img src="/images/settings.png" alt="settings" /></div></Link>}
                    {location.pathname === "/profile" && <Link to="/"><div className="header_back"><img className="caret_left" src="/images/caret.png" alt="settings" />&nbsp;Back</div></Link>} */}
                </div>
            </div>
            <Outlet context={{ tonConnectRef }} />
        </>
    )
};

export default Layout;