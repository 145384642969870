import React, { useState, useEffect, act } from 'react';
import { InfoApiService } from '../services/info.api.service';
import { SendCoin } from './send-coin';
import { DepositLoodWallet } from './deposit-lood-wallet';
import { formattedNumber } from './formattedNumber';
import { ActionApiService } from '../services/action.api.service';
import { toast, Bounce } from 'react-toastify';
import Confetti from 'react-confetti'; // Импортируем библиотеку конфетти
import { WithdrawLoodWallet } from './withdraw-lood-wallet';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { formattedNumberShort } from './formattedNumberShort';

const SpinSlot = ({ index, isSpinning, lay, finalImage, activeCoin }) => {
    const [stripPosition, setStripPosition] = useState(0);
    const imageHeight = 100; // Высота контейнера для одного изображения
    const [images] = useState([1, 2, 3, 4, 5]); // Массив изображений

    useEffect(() => {
        let animationFrame;
        let startTimeout;
        let stopTimeout;
        let speed = 15; // Начальная скорость

        const animate = () => {
            setStripPosition(prev => {
                const newPosition = prev - speed;
                return (newPosition + images.length * imageHeight) % (images.length * imageHeight);
            });
            animationFrame = requestAnimationFrame(animate);
        };

        if (isSpinning) {
            startTimeout = setTimeout(() => {
                animationFrame = requestAnimationFrame(animate);
            }, lay);

            stopTimeout = setTimeout(() => {
                cancelAnimationFrame(animationFrame);
                const finalPosition = (finalImage - 1) * imageHeight;
                setStripPosition(finalPosition);
            }, 3500 + lay); // Увеличено время остановки
        }

        return () => {
            clearTimeout(startTimeout);
            clearTimeout(stopTimeout);
            cancelAnimationFrame(animationFrame);
        };
    }, [isSpinning, lay, finalImage, images.length]);


    // Обработчик события resize
    const handleResize = () => {
        // Принудительное обновление состояния или логика для обработки изменения размера
        setStripPosition(prev => prev); // Это может быть заменено на более сложную логику, если необходимо
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={{
            width: '100px',
            height: `${imageHeight}px`,
            overflow: 'hidden',
            position: 'relative',
            perspective: '1000px', // Эффект перспективы
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.8)', // Темная тень для контейнера
            borderRadius: '0px', // Закругленные углы
            background: 'linear-gradient(145deg, #ffffff, #e0e0e0)', // Изменен фон на светлый
            padding: '5px 0px', // Отступы для рамки
        }}>
            <div
                style={{
                    position: 'absolute',
                    top: `-${stripPosition}px`,
                    transition: isSpinning ? 'none' : 'top 0.5s ease-out',
                    transform: 'translateZ(0)',
                    willChange: 'transform'
                }}
            >
                {images.concat(images).map((num, i) => (
                    <div key={i} style={{
                        height: `${imageHeight}px`,
                        width: '95px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)', // Тень для каждого изображения
                        margin: '0', // Убраны отступы между изображениями
                        background: 'rgba(255, 255, 255, 0.1)', // Полупрозрачный фон для изображений
                        position: 'relative', // Для абсолютного позиционирования эффекта
                    }}>
                        <img
                            src={`/images/slots/${activeCoin?.title ? activeCoin?.title.toLowerCase() : "wend"}/${num}.png`}
                            alt={`slot ${num}`}
                            style={{ objectFit: 'contain', width: '100%', height: '100%', borderRadius: '0' }} // Убраны закругления
                        />
                        <div style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            right: '0',
                            bottom: '0',
                            background: 'rgba(255, 255, 255, 0.2)', // Полупрозрачный светящийся эффект
                            filter: 'blur(5px)', // Размытие для светящегося эффекта
                            opacity: isSpinning ? 0.5 : 0, // Светящийся эффект при вращении
                            transition: 'opacity 0.3s ease', // Плавный переход,
                            willChange: 'opacity'
                        }} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export const Spin = ({ user }) => {
    const [spinning, setSpinning] = useState(false);
    const [results, setResults] = useState([1, 1, 1]); // Начальные значения
    const [globalHighlight, setGlobalHighlight] = useState(false); // Переименованное состояние для подсветки
    const [winEffect, setWinEffect] = useState(false); // Новое состояние для эффекта выигрыша
    const [currentBalances, setCurrentBalances] = useState([]);
    const [showDepositModal, setShowDepositModal] = useState(false);
    const [showWithdrawModal, setShowWithdrawModal] = useState(false);
    const [coinSelectPopupVisible, setCoinSelectPopupVisible] = useState(false);
    const [activeCoin, setActiveCoin] = useState(null);
    const [betAmount, setBetAmount] = useState(0);
    const [showConfetti, setShowConfetti] = useState(false);

    const handleSpin = (first, second, third) => {
        if (spinning) return;

        const newResults = [
            first,
            second,
            third
        ];

        setResults(newResults);
        setSpinning(true);
        setGlobalHighlight(true); // Включаем подсветку при вращении
        setShowConfetti(true); // Включаем конфетти при вращении

        // Уведомление о запуске
        toast.success("Вращение началось!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
        });

        setTimeout(() => {
            setSpinning(false);
            setGlobalHighlight(false);
            setShowConfetti(false); // Выключаем конфетти после вращения
            fetchBalances();
        }, 5000); // Увеличено общее время вращения
    };

    const handleWin = (winImage) => {
        if (spinning) return;
        const winResults = [winImage, winImage, winImage]; // Три одинаковые картинки
        setResults(winResults);
        setSpinning(true);
        setGlobalHighlight(true); // Включаем подсветку при выигрыше

        setTimeout(() => {
            setSpinning(false);
            setShowConfetti(true); // Включаем конфетти после завершения вращения

            // Убираем конфетти через 3 секунды
            setTimeout(() => {
                setShowConfetti(false); // Выключаем конфетти
            }, 5000); // Время отображения конфетти
            fetchBalances();
        }, 5000); // Время вращения
    };

    const handleLoss = () => {
        if (spinning) return;

        let newResults;
        do {
            newResults = [
                Math.floor(Math.random() * 5) + 1,
                Math.floor(Math.random() * 5) + 1,
                Math.floor(Math.random() * 5) + 1
            ];
        } while (newResults[0] === newResults[1] && newResults[1] === newResults[2]);

        setResults(newResults);
        setSpinning(true);
        setGlobalHighlight(true); // Включаем подсветку при проигрыше

        setTimeout(() => {
            setSpinning(false);
            setGlobalHighlight(false); // Выключаем подсветку после вращения
            fetchBalances();
        }, 5000);
    };

    const fetchBalances = async () => {
        try {
            const result = await InfoApiService.GetSpinBalances();
            setSpinning((prevSpinning) => {
                if (result && !prevSpinning) {
                    setCurrentBalances(result);
                }
                return prevSpinning; // Возвращаем предыдущее значение
            });

        } catch (error) {
        }
    };


    useEffect(() => {
        if (currentBalances && currentBalances.length > 0) {
            if (!activeCoin) {
                setActiveCoin(currentBalances.sort((a, b) => b.balance - a.balance)[0]);
            }
            else {
                let currentActiveBalance = currentBalances.find(x => x.coinAddress == activeCoin.coinAddress);
                if (currentActiveBalance)
                    setActiveCoin(currentActiveBalance);
            }
        }

    }, [currentBalances]);


    useEffect(() => {
        if (activeCoin) {
            if (betAmount > activeCoin?.balance)
                setBetAmount(activeCoin?.minSpin)
        }

    }, [activeCoin]);

    useEffect(() => {
        fetchBalances();

        const intervalId = setInterval(fetchBalances, 30000);

        // Очистка интервала при размонтировании компонента
        return () => clearInterval(intervalId);
    }, []);


    const startDeposit = () => {
        setShowDepositModal(true);
    }

    const startWithdraw = () => {
        setShowWithdrawModal(true);
    }


    function assetClick(coin) {
        setActiveCoin(coin);
        setCoinSelectPopupVisible(false);
    }

    const calculateStep = (minSpin, maxSpin, second, third, fourth) => {
        if (!minSpin || !maxSpin)
            return [{
                value: 0,
                label: `0`,
            }];

        if (minSpin >= maxSpin) {
            return [{
                value: 0,
                label: `0`,
            }];
        }

        return [
            {
                value: minSpin,
                label: formattedNumberShort(minSpin, 1),
            },
            {
                value: second,
                label: formattedNumberShort(second, 1),
            },
            {
                value: third,
                label: formattedNumberShort(third, 1),
            },
            {
                value: fourth,
                label: formattedNumberShort(fourth, 1),
            },
            {
                value: maxSpin,
                label: formattedNumberShort(maxSpin, 1),
            },
        ];

        /*         const step = (maxSpin - minSpin) / 4;
        
                const stepsArray = Array.from({ length: 5 }, (_, index) => {
                    const value = Math.round(minSpin + step * index);
                    return {
                        value: value,
                        label: formattedNumberShort(value, 1),
                    };
                });
        
        
                return stepsArray; */
    };

    const spin = () => {
        let spinAmount = betAmount;
        if (spinAmount === 0)
            spinAmount = activeCoin?.minSpin;
        if (activeCoin && activeCoin.balance >= activeCoin.minSpin && spinAmount >= activeCoin.minSpin && spinAmount <= activeCoin.maxSpin) {
            ActionApiService.Spin(spinAmount, activeCoin.coinAddress).then(result => {
                if (result.isSuccess === false) {
                    toast.error(result.message ?? 'Failed.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "dark",
                        transition: Bounce,
                    });
                }
                else {
                    result.data.win === true ? handleWin(result.data.multiplier) : handleLoss();
                }
            })
        }
    }

    return (
        <div style={{ position: 'relative', overflow: 'hidden', maxWidth: 450, margin: "auto" }}>
            {showConfetti && (
                <Confetti
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 10
                    }}
                />
            )}
            <div className={`burn_section ${winEffect ? 'win-effect' : ''}`}>
                <div className='burn_action_crisper' style={{ marginTop: 0 }}>
                    {activeCoin &&
                        <div className='spin_toolbar'>
                            <div className="swap_coin_type_fs" onClick={() => setCoinSelectPopupVisible(true)}>
                                <img className="swap_coin_type_icon" src={activeCoin?.image} width={32} height={32} />
                                <div className="swap_coin_type_title">{activeCoin?.title}</div>
                                <img className='swap_coin_type_caret' src="/images/caret.png" width={24} />
                            </div>
                            {activeCoin?.balance > 0 &&
                                <div className="df " style={{ paddingRight: 12 }}>
                                    <div onClick={() => startDeposit()} className={'spin_toolbar_icon'}>
                                        <img width={40} src="/images/credit-card-black_in.svg" title="Deposit" alt="Deposit" />
                                    </div>

                                    <div onClick={() => startWithdraw(true)} className={"spin_toolbar_icon " + (activeCoin?.balance > 0 ? '' : 'unclickable')}>
                                        <img width={40} src="/images/credit-card-black_out.svg" title="Withdraw" alt="Withdraw" />
                                    </div>

                                </div>
                            }

                            {activeCoin?.balance === 0 &&
                                <div onClick={() => startDeposit()} className="df deposit_toolbar_with_text" style={{ paddingRight: 12 }}>
                                    Deposit
                                    <div className={'spin_toolbar_icon'}>
                                        <img width={40} src="/images/credit-card-black_in.svg" title="Deposit" alt="Deposit" />
                                    </div>
                                </div>
                            }

                        </div>
                    }

                    <div className={currentBalances && activeCoin?.balance >= activeCoin?.minSpin ? "mtxl spin_crisper" : "mtxl spin_crisper"} >
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                            <h2 className="spin_header">Spin&Burn</h2>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '120px' }}>
                            {[0, 1, 2].map((index) => (
                                <div key={index} className={globalHighlight ? "spin_slot_gh" : "spin_slot"}>
                                    <SpinSlot
                                        key={index}
                                        index={index}
                                        isSpinning={spinning}
                                        lay={index * 500}
                                        finalImage={results[index]}
                                        activeCoin={activeCoin}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className='spin-balance'>
                            {formattedNumberShort(currentBalances?.find(x => x.coinAddress == activeCoin?.coinAddress)?.balance ?? activeCoin?.balance)}
                        </div>
                        <div className='spinning_utility_bar'>
                            <div className='spinning_combo_info'>
                                {[1, 2, 3, 4, 5].map((index) => (
                                    <div key={index} className='spinning_combo_info_item'>
                                        <div className='spinning_combo_info_images'>
                                            <img src={'/images/slots/' + (activeCoin?.title ? activeCoin?.title.toLowerCase() : "wend") + "/" + index + ".png"} />
                                            <img src={'/images/slots/' + (activeCoin?.title ? activeCoin?.title.toLowerCase() : "wend") + "/" + index + ".png"} />
                                            <img src={'/images/slots/' + (activeCoin?.title ? activeCoin?.title.toLowerCase() : "wend") + "/" + index + ".png"} />
                                        </div>
                                        <div className='spinning_combo_info_text semibold text_white'>x{index === 1 ? 2 : index === 2 ? 5 : index === 3 ? 7 : index === 4 ? 10 : 20}</div>
                                    </div>
                                ))}

                            </div>
                            <div className={'spinning_bet_menu' + (currentBalances && activeCoin?.balance >= activeCoin?.minSpin ? ' ' : ' unclickable')}>
                                <div className='spinning_bet_menu_display_bet_amount semibold text_white'>
                                    {betAmount === 0 ? activeCoin?.minSpin : betAmount}
                                </div>
                                <IOSSlider
                                    min={activeCoin?.minSpin ?? 0}
                                    step={null}
                                    marks={calculateStep(activeCoin?.minSpin, activeCoin?.maxSpin, activeCoin?.secondSpin, activeCoin?.thirdSpin, activeCoin?.fourthSpin)}
                                    max={activeCoin?.maxSpin ?? 0}
                                    className=''
                                    value={betAmount}
                                    onChange={(e, newValue) => setBetAmount(newValue)}
                                />
                                <button
                                    className={'spin_button ' + (spinning === true ? 'unclickable' : "")}
                                    onClick={() => spin()}
                                    disabled={spinning}
                                >Spin</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='asset_title' style={{ padding: 12 }}>This game is not a real-money gambling game.<br /> The app is in Beta, use at your own risk.</div>
            <DepositLoodWallet minHeight={550} shown={showDepositModal} allWalletsBalance={user?.allWalletsBalance} whiteList={currentBalances.map(x => x.coinAddress)} tonBalance={user?.balance} userWallet={user?.wallet} close={() => {
                setShowDepositModal(false);
            }} />
            <WithdrawLoodWallet minHeight={550} shown={showWithdrawModal} coinBalance={activeCoin} tonBalance={user?.balance} close={() => {
                setShowWithdrawModal(false);
            }} />
            {
                coinSelectPopupVisible &&
                (
                    <div className={`modal_container visible`}>
                        <div onClick={() => { setCoinSelectPopupVisible(false) }} className='modal_overlay_half'></div>
                        <div className='bottom_stuck_modal half_stuck_modal modal_scrollable' style={{ minHeight: 400 }}>
                            <div className='bottom_stuck_modal_content pdside16'>
                                <div className='mtxl'></div>
                                <div className='popup_title'>
                                    Select asset to spin
                                </div>
                                <div className="settings_coin_balances">
                                    {currentBalances != null && currentBalances.length > 0 && currentBalances.sort((a, b) => b.balance - a.balance).map((coin, index) =>
                                        <div className={'asset_item_plain'} key={index} onClick={() => assetClick(coin)}>
                                            <img className='asset_item_image' src={coin?.image} />
                                            <div className='asset_desc'>
                                                <div className='asset_short_title'>{coin?.title}</div>
                                            </div>
                                            <div className='asset_balance_desc'>
                                                <div className='asset_balance'>{formattedNumber(coin?.balance ?? 0)}</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                )
            }
        </div >
    );
};



const iOSBoxShadow =
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const IOSSlider = styled(Slider)(({ theme }) => ({
    color: '#00BFFF',
    height: 5,
    paddingBottom: '0!important',
    '& .MuiSlider-thumb': {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
        '&:focus, &:hover, &.Mui-active': {
            boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: iOSBoxShadow,
            },
        },
        '&:before': {
            boxShadow:
                '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
        },
    },
    '& .MuiSlider-markLabel ': {
        color: "#ffffff",
        fontFamily: 'SF UI Display Semibold',
        display: "none"
    },
    '& span.MuiSlider-markLabel[data-index="0"] ': {
    },
    '& .MuiSlider-valueLabel ': {
        borderRadius: 16,
        backgroundColor: '#3D3D3D'
    },
    '& .MuiSlider-track': {
        border: 'none',
        height: 5,
    },
    '& .MuiSlider-rail': {
        opacity: 0.5,
        boxShadow: 'inset 0px 0px 4px -2px #000',
        backgroundColor: '#d0d0d0',
    },
    ...theme.applyStyles('dark', {
        color: '#0a84ff',
    }),
}));