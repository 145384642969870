import React, { useState, useEffect, useReducer } from 'react';
import { TonConnectButton, useTonConnectUI } from '@tonconnect/ui-react';
import { beginCell, Address, toNano } from '@ton/ton'
import { useTonAddress } from '@tonconnect/ui-react';
import TonWeb from 'tonweb'
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { InfoApiService } from '../services/info.api.service';
import { formattedNumber } from './formattedNumber';
import { cuttedWallet } from '../components/cuttedWallet';
import loadingAD from '../lotties/loading.json';
import Lottie from 'react-lottie';
import { styled } from '@mui/material/styles';
import FormattedNumberInput from './formattedNumberInput';
import { toast, Bounce } from 'react-toastify';

export const TonConnectLoodDepositCrisper = ({ mainWallet, whiteList }) => {
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const userFriendlyAddress = useTonAddress();
    const [connectedBalances, setConnectedBalances] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [initButtonVisible, setInitButtonVisible] = useState(false);


    const loadingOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };



    const createJettonTransferPayload = (amount, destination, response_destination) => {
        const body = beginCell()
            .storeUint(0xf8a7ea5, 32)                 // jetton transfer op code
            .storeUint(0, 64)                         // query_id:uint64
            .storeCoins(amount)              // amount:(VarUInteger 16) -  Jetton amount for transfer (decimals = 6 - USDT, 9 - default). Function toNano use decimals = 9 (remember it)
            .storeAddress(Address.parse(destination))  // destination:MsgAddress
            .storeAddress(Address.parse(response_destination))  // response_destination:MsgAddress
            .storeUint(0, 1)                          // custom_payload:(Maybe ^Cell)
            .storeCoins("0")                 // forward_ton_amount:(VarUInteger 16) - if >0, will send notification message
            .storeUint(0, 1)                           // forward_payload:(Either Cell ^Cell)
            .endCell();

        return body.toBoc().toString("base64");
    };

    const handleJettonTransfer = async () => {
        if (!tonConnectUI) {
            console.error('Wallet is not connected');
            return;
        }

        if (connectedBalances && connectedBalances.some(x => x.transferAmount > 0)) {
            let messages = [];
            let trackRequest = {};

            for (let balanceForTransfer of connectedBalances) {
                if (!balanceForTransfer.decimals)
                    continue;
                if (balanceForTransfer.transferAmount > 0 && messages.length === 0) {
                    messages.push({
                        address: balanceForTransfer.jettonWalletAddress, // Адрес контракта токена
                        amount: "50000000",
                        payload: createJettonTransferPayload(balanceForTransfer.transferAmount * 10 ** balanceForTransfer.decimals, "EQBdR35q7WeGx8RfUzYr6BOH45cqYeJxwvdO8Vbqgx3RzZiw", userFriendlyAddress), // Полезная нагрузка для сжигания
                    });

                    trackRequest = {
                        hash: "",
                        sender: userFriendlyAddress,
                        reason: 200,
                        coinAddress: balanceForTransfer.address,
                        amount: balanceForTransfer.transferAmount
                    };
                }
            }

            let commissionMessage = {
                amount: 0.1 * 10 ** 9,
                address: "UQDXw6c8EpDJEtrL3yhTzO_WICc2zZ4BTrlSWshD-EfTp3AB"
            };

            messages.push(commissionMessage);

            const transaction = {
                validUntil: Date.now() + 5 * 60 * 1000,
                messages: messages
            };

            try {
                const result = await tonConnectUI.sendTransaction(transaction);
                const bocCellBytes = await TonWeb.boc.Cell.oneFromBoc(TonWeb.utils.base64ToBytes(result.boc)).hash();
                const hashBase64 = TonWeb.utils.bytesToBase64(bocCellBytes);
                trackRequest.hash = hashBase64;
                await InfoApiService.TrackTransfer(trackRequest);
                toast.success('Deposit confirmed. Balance will update within 2 minutes!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
            } catch (error) {
                console.error('Error sending transaction:', error);
            }
        }

    };


    const transferAmountChange = (address, event) => {
        let balance = connectedBalances.find(x => x.address == address);
        if (!balance) {
            console.log('???');
            return;
        }

        balance.transferAmount = Number(event.target.value);

        if (balance.transferAmount > balance.balance)
            balance.transferAmount = balance.balance;

        if (balance.transferAmount > 0)
            resetTransferAmountExceptOne(address);

        let newConnectedBalances = [...connectedBalances];

        setConnectedBalances(newConnectedBalances);
    }


    const transferAmountChangeWithValue = (address, value) => {
        let balance = connectedBalances.find(x => x.address == address);
        if (!balance) {
            console.log('???');
            return;
        }

        balance.transferAmount = Number(value);
        if (balance.transferAmount > balance.balance)
            balance.transferAmount = balance.balance;

        if (balance.transferAmount > 0)
            resetTransferAmountExceptOne(address);

        let newConnectedBalances = [...connectedBalances];

        setConnectedBalances(newConnectedBalances);
    }

    const resetTransferAmountExceptOne = (address) => {
        for (let balance of connectedBalances) {
            if (balance.address != address)
                balance.transferAmount = 0;
        }
    }

    useEffect(() => {
        if (userFriendlyAddress) {
            setIsLoading(true);
            InfoApiService.GetExternalWalletBalances(userFriendlyAddress, true, false).then(result => {
                if (result)
                    setConnectedBalances(result);
            }).finally(() => setIsLoading(false));
        }
        else {
            setConnectedBalances([]);
            rerenderInitButton();
        }

    }, [userFriendlyAddress]);

    const rerenderInitButton = () => {
        setInitButtonVisible(false);

        setTimeout(() => {
            setInitButtonVisible(true);
        }, 100);
    }



    const calculateStep = (balance) => {
        let step;
        if (balance >= 1000000) {
            step = 10000; // Для очень больших значений
        }
        else if (balance >= 10000) {
            step = 1000; // Для больших значений
        }
        else if (balance >= 10000) {
            step = 500; // Для больших значений
        } else if (balance >= 1000) {
            step = 100; // Для больших значений
        } else if (balance >= 100) {
            step = 10; // Для средних значений
        }
        else if (balance >= 10) {
            step = 1; // Для средних значений
        }
        else if (balance >= 1) {
            step = 0.1; // Для значений от 1 до 100
        } else if (balance >= 0.1) {
            step = 0.01; // Для значений от 0.1 до 1
        } else if (balance >= 0.01) {
            step = 0.001; // Для значений от 0.01 до 0.1
        } else {
            step = 0.0001; // Для значений меньше 0.01
        }
        return step;
    };


    return (
        <>
            {initButtonVisible && (!userFriendlyAddress || userFriendlyAddress === '') && (
                <TonConnectButton style={{ marginTop: 20 }} />
            )}
            {isLoading &&
                <Lottie
                    options={loadingOptions}
                    height={256}
                    width={256}
                    speed={1.5}
                />}
            {!isLoading && connectedBalances && connectedBalances.length > 0 &&
                <>
                    <div className="settings_coin_balances">
                        <div className='plain_rounded_list_header' style={{ paddingBottom: 30, display: "flex", alignItems: "center", borderBottom: "none" }}>Deposit from
                            <TonConnectButton className="my-button-class" style={{ marginLeft: 10 }} />
                        </div>
                        {connectedBalances.filter(x => whiteList.includes(x.address)).sort((a, b) => {
                            if (a.title === 'Toncoin' && b.title !== 'Toncoin') return -1; // a выше b
                            if (b.title === 'Toncoin' && a.title !== 'Toncoin') return 1;  //
                            return b.balance - a.balance;
                        }).map((coin, index) =>
                            <div className='asset_item_plain asset_item_dark' key={index}>
                                <img className='asset_item_image' src={coin?.image} />
                                <div className='asset_desc'>
                                    <div className='asset_short_title'>{coin?.shortTitle}</div>
                                    <div className='asset_title' style={{ maxWidth: 120, textOverflow: 'ellipsis', overflow: 'hidden' }}>{formattedNumber(coin.balance)}</div>
                                </div>
                                <div className='asset_balance_desc' style={{ display: 'flex', flexDirection: 'column', marginRight: 0 }}>
                                    <FormattedNumberInput className="transfer_amount_input" value={coin.transferAmount ?? 0}
                                        onChange={(e) => transferAmountChangeWithValue(coin.address, e)}
                                    ></FormattedNumberInput>
                                    <IOSSlider
                                        min={0}
                                        step={calculateStep(coin.balance)}
                                        max={coin.balance}
                                        className='transfer_amount_slider'
                                        value={coin.transferAmount ?? 0}
                                        onChange={(e, newValue) => transferAmountChange(coin.address, e, newValue)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    {/*       <button className='default_next_btn ' onClick={handleSendTon}>
                        Send 0.01 TON
                    </button>
                    <button className='default_next_btn ' onClick={handleBurnJetton}>
                        Burn 10 WEND
                    </button> */}

                    {connectedBalances.length > 0 && connectedBalances.some(x => x.transferAmount > 0) &&
                        connectedBalances.filter(x => x.transferAmount > 0).length < 5 &&
                        <div className={'default_next_btn btn_red '} onClick={handleJettonTransfer}>
                            Deposit
                        </div>
                    }

                    {connectedBalances.length === 0 || !connectedBalances.some(x => x.transferAmount > 0) &&
                        <div className={'default_next_btn btn_red unclickable'} onClick={handleJettonTransfer}>
                            Deposit
                        </div>
                    }

                    {connectedBalances.filter(x => x.transferAmount > 0).length > 1 &&
                        <div className={'default_next_btn btn_red unclickable'} >
                            Max 1 Transfer at a time
                        </div>
                    }

                </>
            }
        </>
    );
}

const iOSBoxShadow =
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const IOSSlider = styled(Slider)(({ theme }) => ({
    color: '#007bff',
    height: 5,
    paddingBottom: '0!important',
    '& .MuiSlider-thumb': {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
        '&:focus, &:hover, &.Mui-active': {
            boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: iOSBoxShadow,
            },
        },
        '&:before': {
            boxShadow:
                '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
        },
    },
    '& .MuiSlider-markLabel ': {
        color: "#ffffff",
        fontFamily: 'SF UI Display Semibold'
    },
    '& span.MuiSlider-markLabel[data-index="0"] ': {
        left: "0!important",
        display: "none"
    },
    '& .MuiSlider-valueLabel ': {
        borderRadius: 16,
        backgroundColor: '#3D3D3D'
    },
    '& .MuiSlider-track': {
        border: 'none',
        height: 5,
    },
    '& .MuiSlider-rail': {
        opacity: 0.5,
        boxShadow: 'inset 0px 0px 4px -2px #000',
        backgroundColor: '#d0d0d0',
    },
    ...theme.applyStyles('dark', {
        color: '#0a84ff',
    }),
}));