import { cuttedWallet } from "../components/cuttedWallet";
import Lottie from 'react-lottie';
import loadingAD from '../lotties/loading.json';
import { useUser } from "../components/user";
import { SeedPopup } from "../components/seed-popup";
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { toast, Bounce } from 'react-toastify';
import { UiService } from "../services/ui.service";
import { SendCoin } from "../components/send-coin";
import { BurnCoin } from "../components/burn-coin";
import fireAD from '../lotties/fire.json';
import { TgBotSetupApiService } from "../services/tgBotSetup.api.service";

const TgBotSetup = () => {
  const [controlsDisabled, setControlsDisabled] = useState(false);
  const [controlsDeleteDisabled, setControlsDeleteDisabled] = useState(false);
  const [currentTracks, setCurrentTracks] = useState([]);
  const [currentGroups, setCurrentGroups] = useState([]);
  const [currentCoinAddress, setCurrentCoinAddress] = useState('');
  const [currentGroup, setCurrentGroup] = useState(null);
  const [groupSelectVisible, setGroupSelectVisible] = useState(false);


  window.Telegram.WebApp.BackButton.show();
  const initData = window?.Telegram?.WebApp?.initData;
  const { user, isLoading, isError } = useUser(initData);
  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


  useEffect(() => {
    if (!isLoading && !isError) {
      updatePageInfo();
    }

  }, [isLoading, isError]);

  function updatePageInfo() {
    TgBotSetupApiService.GetCurrentTracks().then(result => {
      setCurrentGroups(result.allowedGroups);
      setCurrentTracks(result.currentLinks);
    });
  }

  if (isLoading || isError) {
    return (
      <div className="page">
        <Lottie
          options={loadingOptions}
          height={256}
          width={256}
          speed={1.5}
        />
      </div>
    );
  }

  function updateTracking() {
    if (currentGroup && currentGroup?.id != null && currentCoinAddress && currentCoinAddress !== "") {
      setControlsDisabled(true);
      TgBotSetupApiService.CreateTrack(currentCoinAddress, currentGroup.id).then(result => {
        if (result.success) {
          UiService.SuccessToast(result.message);
          updatePageInfo();
        }
        else {
          UiService.ErrorToast(result.message);
        }
      }).finally(() => setControlsDisabled(false));
    }
  }

  function removeTracking(id) {
    setControlsDeleteDisabled(true);
    TgBotSetupApiService.RemoveTrack(id).then(result => {
      if (result.success) {
        UiService.SuccessToast(result.message);
        updatePageInfo();
      }
      else {
        UiService.ErrorToast(result.message);
      }
    }).finally(() => setControlsDeleteDisabled(false));

  }

  const handleGroupSelect = (group) => {
    setCurrentGroup(group);
    setGroupSelectVisible(false); // Закрыть дропдаун после выбора
  };


  const fireOptions = {
    loop: true,
    autoplay: true,
    animationData: fireAD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


  let onboardingCompleted = localStorage.getItem('_bb_onboarding_completed');
  if (onboardingCompleted !== true && onboardingCompleted !== "true") {
    return <div></div>;
  }


  return (
    <div className="page">
      <div className={'rounded_crisper_dark mtl ' + (controlsDisabled === true ? 'unclickable' : "")}>
        <div className="swap_coin_type_fs mt12 padding16" onClick={() => setGroupSelectVisible(true)}>
          <div className={`semibold_white_text fs20 ${currentGroup ? "" : "placeholder_color"}`}>{currentGroup?.title ?? "Select group"}</div>
          <img className='swap_coin_type_caret' src="/images/caret.png" width={24} />
        </div>
        <input
          type="text"
          placeholder="Contract Address"
          onChange={(e) => setCurrentCoinAddress(e.target.value)}
          onPaste={(e) => setCurrentCoinAddress(e.target.value)}
          className={"rounded_input mt12 "}
        /*           value={sendAmount}
                  onChange={setSendAmount} */
        />
        {/*         {sendAmountErrorMessage && sendAmountErrorMessage !== '' &&
          <div className='amount_input_error_message mtxl'>{sendAmountErrorMessage}</div>
        } */}

        <div onClick={() => updateTracking()} className={"default_next_btn btn_red burn_button mt12 df jcc aic " + (currentGroup && currentGroup?.id != null && currentCoinAddress && currentCoinAddress !== "" ? "" : "unclickable")}>
          <Lottie
            style={{ margin: "unset", marginTop: -3 }}
            options={fireOptions}
            height={20}
            width={20} />
          &nbsp;Track Burns
        </div>
      </div>

      {
        currentTracks && currentTracks.length > 0 &&
        <div className={'rounded_crisper_dark mtl ' + (controlsDeleteDisabled === true ? "unclickable" : "")}>
          <div className="plain_rounded_list_crisper">
            <div className='plain_rounded_list_header'>Current Links</div>

            {currentTracks.map(link =>
              <div key={link.id} className='plain_rounded_list_item'>
                <div className="plain_rounded_list_item_left">
                  <div className='plain_rounded_list_item_title'>{link.groupTitle}</div>
                  <div className='plain_rounded_list_item_subtitle'>Tracked asset: {link.assetTitle}</div>
                </div>
                <div className="plain_rounded_list_item_right">
                  <img width={32} height={32} src="/images/close_cross.png" onClick={() => removeTracking(link.id)} />
                </div>
              </div>
            )}
          </div>
        </div>

      }


{
              groupSelectVisible &&
              (
                <div className={`modal_container visible group_select_popup`}>
                  <div onClick={() => { setGroupSelectVisible(false) }} className='modal_overlay_half'></div>
                  <div className='bottom_stuck_modal half_stuck_modal modal_scrollable' style={{ minHeight: 400 }}>
                    <div className='bottom_stuck_modal_content pdside16'>
                      <div className='mtxl'></div>
                      <div className='popup_title'>
                        Select group
                      </div>
                      <div className="settings_coin_balances mtl">
                        {currentGroups != null && currentGroups.length > 0 && currentGroups.map((group, index) =>
                          <div className={'asset_item_plain'} key={index} onClick={() => handleGroupSelect(group)}>
                            <div className='asset_desc'>
                              <div className='asset_short_title'>{group?.title}</div>
                            </div>
                          </div>
                        )}

                      </div>
                    </div>

                  </div>
                </div>
              )
            }

    </div>

  );
};

export default TgBotSetup;