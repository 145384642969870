import useSWR from 'swr'
import { MainApiService } from "../services/main.api.service";
import { useSWRConfig } from "swr";
import { ProfileApiService } from '../services/profile.api.service';

export function useUser(initData) {
    const swrFetcher = async url => {
        const accessToken = localStorage.getItem('bb_token');
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const result = await fetch(url, {headers});

        if (result.status !== 200) {
          const error = new Error('An error occurred while fetching the data.');
          error.info = 'Bad request';
          error.status = result.status;
          throw error
        }
       
        return result.json()
      }

    const profileHookUrl = ProfileApiService.GetProfileHookUrl();
    const { data, error, isLoading } = useSWR(profileHookUrl, swrFetcher, { refreshInterval: 0, revalidateOnReconnect: false, revalidateOnFocus: false, revalidateIfStale: false, revalidateOnMount: true })
    const { mutate } = useSWRConfig();

    if (error?.status === 401 && isLoading === false) {
        const refreshToken = localStorage.getItem('bb_refreshToken');
        const accessToken = localStorage.getItem('bb_token');
        fetch(process.env.REACT_APP_API_URL + 'Token/Refresh', {
            method: "POST",
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify({ accessToken, refreshToken })
        }).then(refreshRequest => {
            if (refreshRequest.status === 200) {
                refreshRequest.json().then(refreshJson => {
                    localStorage.setItem('bb_token', refreshJson.accessToken);
                    localStorage.setItem('bb_refreshToken', refreshJson.refreshToken);
                
                    timeout(600).then(x => {
                        mutate(profileHookUrl);
                    });  
                });
            }
            else {
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    if(!initData || initData === ''){
                        initData = "query_id=AAErmW0dAAAAACuZbR2mdCT-&user=%7B%22id%22%3A493721899%2C%22first_name%22%3A%22Ivan%22%2C%22last_name%22%3A%22Sarayan%22%2C%22username%22%3A%22ivansarayan%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1721370103&hash=fce00f8e3fc5cf60c0b29c240887910ed662d6e12f36096b90590fde279f1ce0";
                     }
                } else {
                    // production code
                }
               
                    
                MainApiService.InitLogin(initData)
                    .then(initLoginResponse => {
                        if (initLoginResponse.status === 200) {
                            initLoginResponse.json().then(jsonData => {
                                localStorage.setItem('bb_token', jsonData.accessToken);
                                localStorage.setItem('bb_refreshToken', jsonData.refreshToken);
                            });

                            timeout(600).then(x => {
                                mutate(profileHookUrl);
                            }); 
                        }
                    });
            }
        });
    }

    return {
        user: data,
        isLoading,
        isError: error
    }
}

function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}