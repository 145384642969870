export function formattedNumber(value) {
    const numberValue = parseFloat(value);

    if (isNaN(numberValue)) {
        return ""; // Возвращаем пустую строку для NaN
    }

    if (numberValue === 0) {
        return "0";
    }

    let formattedValue;

    // Определяем форматирование в зависимости от значения
    if (numberValue < 0.0001) {
        formattedValue = numberValue.toFixed(8);
    } else if (numberValue < 0.001) {
        formattedValue = numberValue.toFixed(6);
    } else if (numberValue % 1 === 0) { // Если число целое
        formattedValue = numberValue.toString(); // Преобразуем в строку без дробной части
    } else {
        formattedValue = numberValue.toFixed(3); // Для дробных значений
    }

    // Удаляем лишние нули после последней значащей цифры
    formattedValue = formattedValue.replace(/(\.\d*?[1-9])0+$/, '$1'); // Удаляем нули после последней значащей цифры
    formattedValue = formattedValue.replace(/\.0$/, ''); // Удаляем .0, если дробная часть равна 0

    const [integerPart, fractionalPart] = formattedValue.split('.');
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    // Возвращаем результат без лишних нулей для целых чисел
    return fractionalPart 
        ? `${formattedIntegerPart}.${fractionalPart}` 
        : formattedIntegerPart;
}