
import React, { useEffect, useState, useRef } from 'react';
import { TonHelper } from '../helpers/ton-helper';
import { UiService } from '../services/ui.service';
import { cuttedWallet } from "../components/cuttedWallet";
import { ActionApiService } from '../services/action.api.service';
import { toast, Bounce } from 'react-toastify';
import { LocalStorageHelper } from '../helpers/local-storage-helper';
import Lottie from 'react-lottie';
import loadingAD from '../lotties/loading.json';
import { formattedNumber } from './formattedNumber';
import { TonConnectLoodDepositCrisper } from './ton-connect-lood-deposit-crisper';


export function DepositLoodWallet({ allWalletsBalance, tonBalance, userWallet, whiteList, children, shown, close, minHeight }) {
    const [activeStep, setActiveStep] = useState(1);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [sendAmount, setSendAmount] = useState(0);
    const [sendAmountValid, setSendAmountValid] = useState(false);
    const [sendAmountErrorMessage, setSendAmountErrorMessage] = useState(null);
    const [selectedAssetMetadata, setSelectedAssetMetadata] = useState(null);
    const [amountInputWidth, setAmountInputWidth] = useState(40);
    const [controlsDisabled, setControlsDisabled] = useState(false);
    const [walletMode, setWalletMode] = useState('bb');
    const amount_input_content = useRef();


    let recentAddresses = LocalStorageHelper.GetRecentTonAddresses();

    function closeAndReset() {
        setActiveStep(1);
        setSelectedAssetMetadata(tonMetadata);
        setSelectedAsset(null);
        setSendAmount(0);
        setSendAmountValid(false);
        setSendAmountErrorMessage(null)

        close();
    }

    let tonBalanceNumber = Number(tonBalance.replace(/,/g, '.'));
    let tonMetadata = { shortTitle: 'TON', title: 'Toncoin', image: 'https://assets.dedust.io/images/ton.webp', balance: tonBalanceNumber };

    function nextStep() {
        if (activeStep === 1) {
            setAmountInputWidth(40);
            setSendAmount(0);
            setSendAmountErrorMessage(null);
            setSendAmountValid(false);
        }

        setActiveStep(activeStep + 1);
    }

    useEffect(() => {
        if (shown) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [shown]);

    useEffect(
        () => {
            if (selectedAsset === null) {
                setSelectedAssetMetadata(tonMetadata);
            }
            else {
                setSelectedAssetMetadata(allWalletsBalance.find(x => x.address == selectedAsset));
            }
        }, [selectedAsset]);

    useEffect(() => {
        if (amount_input_content.current)
            setAmountInputWidth(amount_input_content.current.offsetWidth + 8);

        if (!sendAmount || sendAmount === '') {
            setSendAmountValid(false);
        }

        if (sendAmount && sendAmount != '' && selectedAssetMetadata) {
            let sendAmountNumber = Number(removeLeadingZerosRegex(sendAmount.replace(/,/g, '.')));
            let isValid = true;
            let errorMessage = '';

            if (selectedAssetMetadata.shortTitle === "TON") {
                if (tonBalanceNumber < sendAmountNumber) {
                    isValid = false;
                    errorMessage = "Insufficient balance";
                }
            }
            else {
                if (selectedAssetMetadata.balance < sendAmountNumber) {
                    isValid = false;
                    errorMessage = "Insufficient balance";
                }
                else {
                    if (tonBalanceNumber < 0.1) {
                        isValid = false;
                        errorMessage = "Insufficient funds to pay 0.1 TON fee";
                    }
                }
            }

            setSendAmountValid(isValid);
            setSendAmountErrorMessage(errorMessage);
        }

    }, [sendAmount]);


    if (shown === false) {
        return null;
    }

    function confirmAndSend() {
        setControlsDisabled(true);
        ActionApiService.DepositLood(sendAmount, selectedAssetMetadata?.address).then(result => {
            if (result.isSuccess === true) {
                toast.success('Deposit confirmed. Balance will update within 2 minutes!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
                closeAndReset();
            }
            else {
                toast.error(result.message ?? 'Failed.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
            }

            setControlsDisabled(false);
        }).catch((error) => {
            setControlsDisabled(false);
        });
    }


    function sendAmountChanged(sendAmount) {
        setSendAmount(sendAmount);
    }

    function assetClick(assetAddress) {
        if (allWalletsBalance.some(x => x.address == assetAddress && x.balance > 0) || assetAddress === null && tonBalanceNumber > 0) {
            setSelectedAsset(assetAddress);
            nextStep();
        }
    }


    function removeLeadingZerosRegex(str) {
        if (!str)
            return '';

        return str.replace(/^0+(?=\d)/, '');
    }

    const loadingOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };



    return (
        <div className={'modal_container visible'}>
            <div onClick={() => { closeAndReset() }} className='modal_overlay_half'></div>
            <div className='bottom_stuck_modal half_stuck_modal modal_scrollable' style={{ minHeight: minHeight }}>
                <div className='bottom_stuck_modal_content pdside16'>
                    {activeStep > 1 && <div onClick={() => activeStep == 1 ? close() : setActiveStep(activeStep - 1)} className="close_btn mtl"><img src="/images/caret.png" /></div>}
                    {activeStep < 2 &&
                        <div className='badges mtl wallet-mode-badges-light'>
                            <div
                                onClick={() => setWalletMode('bb')}
                                className={`wallet-mode-badge-light ${walletMode === 'bb' ? 'wallet-mode-badge-light-active' : ''}`}
                            >
                                BuyBag
                            </div>
                            <div
                                onClick={() => setWalletMode('tonconnect')}
                                className={`wallet-mode-badge-light ${walletMode === 'tonconnect' ? 'wallet-mode-badge-light-active' : ''}`}
                            >
                                External Wallet
                            </div>
                        </div>
                    }


                    {walletMode === 'bb' &&
                        <>
                            {activeStep === 1 &&
                                <>
                                    <div className='mtl'></div>
                                    <div className='popup_title'>
                                        Select asset to deposit
                                    </div>
                                    <div className="settings_coin_balances">
                                        {allWalletsBalance != null && allWalletsBalance.length > 0 && allWalletsBalance.filter(coin => whiteList.includes(coin.address)).sort((a, b) => b.balance - a.balance).map((coin, index) =>
                                            <div className={'asset_item_plain ' + (selectedAsset === coin?.address ? "" : "")} key={index} onClick={() => assetClick(coin?.address)}>
                                                <img className='asset_item_image' src={coin?.image} />
                                                <div className='asset_desc'>
                                                    <div className='asset_short_title'>{coin?.shortTitle}</div>
                                                    <div className='asset_title'>{coin?.title}</div>
                                                </div>
                                                <div className='asset_balance_desc'>
                                                    <div className='asset_balance'>{formattedNumber(coin?.balance ?? 0)}</div>
                                                    {/* <div className='asset_usd_balance'>$0</div> */}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {/* <div className=""><div onClick={() => nextStep()} className={"onboarding_next_btn " + (allWalletsBalance.some(x => x.address == selectedAsset && x.balance > 0) || selectedAsset === null && tonBalanceNumber > 0 ? "" : "unclickable")}>Continue</div></div> */}
                                </>
                            }
                            {activeStep === 2 &&
                                <>
                                    <div className={'amount_input_crisper ' + (sendAmountErrorMessage && sendAmountErrorMessage !== '' ? 'error' : '')}>
                                        <span className="amount_input_content" ref={amount_input_content}>{!sendAmount || sendAmount === '' ? <span>&nbsp;&nbsp;&nbsp;&nbsp;</span> : sendAmount}</span>
                                        <input inputMode="decimal" pattern="[0-9]*" autoFocus type="number" style={{ width: amountInputWidth }} className="default_input  amount_input" value={sendAmount} onChange={event => sendAmountChanged(removeLeadingZerosRegex(event.target.value))} />
                                        <span className='amount_input_suffix'>{selectedAssetMetadata?.shortTitle}</span>
                                    </div>
                                    {sendAmountErrorMessage && sendAmountErrorMessage !== '' &&
                                        <div className='amount_input_error_message'>{sendAmountErrorMessage}</div>
                                    }
                                    <div className='from_balance_crisper'>
                                        <img src={selectedAssetMetadata?.image ?? TonHelper.NoImageAsset()} className='from_balance_asset_image' />
                                        <div className='from_balance_asset_desc'>
                                            <div className='from_balance_asset_title'>From Balance</div>
                                            <div className='from_balance_asset_value'>{selectedAssetMetadata?.shortTitle === "TON" ? tonBalanceNumber + " TON" : selectedAssetMetadata?.balance + " " + selectedAssetMetadata?.shortTitle}</div>
                                        </div>
                                    </div>

                                    <div className=""><div onClick={() => nextStep()} style={{ marginTop: 12 }} className={"default_next_btn btn_red " + (sendAmountValid === true ? "" : "unclickable")}>Continue</div></div>
                                </>
                            }

                            {activeStep === 3 &&
                                <>
                                    {controlsDisabled === true && <Lottie
                                        options={loadingOptions}
                                        height={200}
                                        width={200}
                                        speed={1.5}
                                    />}
                                    {controlsDisabled === false && selectedAssetMetadata?.image &&
                                        <>
                                            <div className="asset_logo_crisper">
                                                <img src={selectedAssetMetadata?.image} />
                                            </div>
                                            <div className='confirm_send_title'>Confirm action</div>
                                            <div className='confirm_send_subtitle'>Deposit {selectedAssetMetadata?.shortTitle}</div>
                                        </>
                                    }

                                    <div>
                                        <div className="plain_badge">
                                            <div className='plain_badge_title'>
                                                Sender
                                            </div>
                                            <div className='plain_badge_value'>
                                                {cuttedWallet(userWallet)}
                                            </div>
                                        </div>
                                        <div className="plain_badge">
                                            <div className='plain_badge_title'>
                                                Amount
                                            </div>
                                            <div className='plain_badge_value'>
                                                {sendAmount + ' ' + selectedAssetMetadata?.shortTitle}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div onClick={() => confirmAndSend()} className={"default_next_btn btn_red " + (sendAmountValid === true && controlsDisabled === false ? "" : "unclickable")}>
                                            Confirm Deposit
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }

                    {walletMode === 'tonconnect' &&
                        <>
                            <div className='mtl'></div>
                            <TonConnectLoodDepositCrisper whiteList={whiteList} mainWallet={userWallet}></TonConnectLoodDepositCrisper>
                        </>
                    }

                </div>

            </div>
        </div >
    );


}
