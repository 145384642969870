import React from 'react';

export const LoadingContainer = ({ text, width = '300px', height = '20px', textClassName }) => {
    return (
        <>
            {text === null ? <LoadingText width={width} height={height} /> : <div className={textClassName}>{text}</div>}
        </>
    );
};

const LoadingText = ({width, height}) => {
    return (
        <div className="loading-container" style={{ width, height }}>

        </div>
    );
};