
import React, { useState, useEffect } from 'react';
import loadingAD from '../lotties/loading.json';
import { formattedNumber } from './formattedNumber';
import { ProfileApiService } from '../services/profile.api.service';
import { useSWRConfig } from "swr"

export const AirdropProgress = ({ user }) => {
    const [inited, setInited] = useState(false);
    const [airdropProgress, setAirdropProgress] = useState(null);

    const loadingOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    useEffect(() => {
        ProfileApiService.GetAirdropInfo().then(result => {
            setAirdropProgress(result);
        });
    }, []); // Пустой массив зависимостей

    return (
        <>
            {airdropProgress &&
                <div className="menu_content">
                    <div className='semibold text_white fs20'>Airdrop</div>
                    <div className='semibold text_white fs16 mtl tac'>{airdropProgress.levelAlias}</div>
                    <div className='semibold text_white fs16 mtxs tac'>Level {airdropProgress.overallLevel}</div>
                    <div className='progress-group'>
                        <div className='progress-title text_white semibold mtd'>Trades & Burns</div>
                        <div className='progress-bar'>
                            <div className='progress-fill' style={{ width: airdropProgress.tradesProgress + '%' }}></div>
                        </div>
                        <div className='progress-levels'>
                            <span className='regularLightText'>level {airdropProgress.tradesLevel}</span>
                            <span className='regularLightText'>level {airdropProgress.tradesLevel + 1}</span>
                        </div>
                    </div>

                    <div className='progress-group'>
                        <div className='progress-title text_white semibold'>Mystery Loot</div>
                        <div className='progress-bar'>
                            <div className='progress-fill' style={{ width: airdropProgress.mysteryProgress + '%' }}></div>
                        </div>
                        <div className='progress-levels'>
                            <span className='regularLightText'>level {airdropProgress.mysteryLevel}</span>
                            <span className='regularLightText'>level {airdropProgress.mysteryLevel + 1}</span>
                        </div>
                    </div>

                    <div className='progress-group'>
                        <div className='progress-title text_white semibold'>Referrals</div>
                        <div className='progress-bar'>
                            <div className='progress-fill' style={{ width: airdropProgress.referralsProgress + '%' }}></div>
                        </div>
                        <div className='progress-levels'>
                            <span className='regularLightText'>level {airdropProgress.referralsLevel}</span>
                            <span className='regularLightText'>level {airdropProgress.referralsLevel + 1}</span>
                        </div>
                    </div>
                </div>
            }

        </>
    );
}
