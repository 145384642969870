import TonWeb from "tonweb";

export class TonHelper {
    static NoImageAsset() {
        return "https://cache.tonapi.io/imgproxy/cOMlJuViiVXDCkAghnyNj7plX8pAZ9pv3WhklvebpTY/rs:fill:200:200:1/g:no/aHR0cHM6Ly9yYXcuZ2l0aHVidXNlcmNvbnRlbnQuY29tL3RvbmtlZXBlci9vcGVudG9uYXBpL21hc3Rlci9wa2cvcmVmZXJlbmNlcy9tZWRpYS90b2tlbl9wbGFjZWhvbGRlci5wbmc.webp";
    }

    static WendAsset() {
        return { shortTitle: 'WEND', title: 'WEN DIZEL', image: '/images/WEND_icon.png', address: 'EQBeruz-RXKQFsVHh6busCylN3_VyeZ2KLyC4hiulg-nQVfj' };
    }


    static TonImageAsset() {
        return "https://assets.dedust.io/images/ton.webp";
    }


    static GetBounceableAddressSafely(address) {
        try {
            const tonweb = new TonWeb();
            return new tonweb.utils.Address(address).toString(true, true, true, false);
        }
        catch {
            return address;
        }
    }

    static GetBounceableAddress(address) {
        const tonweb = new TonWeb();
        return new tonweb.utils.Address(address).toString(true, true, true, false);
    }

    static GetBounceableAddressOrNull(address) {
        try {
            const tonweb = new TonWeb();
            return new tonweb.utils.Address(address).toString(true, true, true, false);
        }
        catch {
            return null;
        }
    }

}