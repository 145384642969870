// jetton-page.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InfoApiService } from '../services/info.api.service';

const JettonPage = () => {
    const { jettonAddress } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(true);

    useEffect(() => {
        InfoApiService.GetJettonPageInfo(jettonAddress).then(data => {
            setData(data);
            setLoading(false);
            setError(false);
        })
            .catch(error => {
                setLoading(false);
            });

    }, [jettonAddress]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading data</div>;

    return (
        <div className='page' style={{ maxWidth: '100vw' }}>
            <iframe height="400px" width="95%" id="geckoterminal-embed" title="GeckoTerminal Embed" src="https://www.geckoterminal.com/ru/ton/pools/EQB5zkvly5vJx7pZrdvbbJKl3Ir9pQ5Pa3uUGIBuimtvZFMB?embed=1&info=0&swaps=0" frameborder="0" allow="clipboard-write" allowfullscreen></iframe>
        </div>
    );
};

export default JettonPage;