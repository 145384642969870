
import React, { useState } from 'react';
import Lottie from 'react-lottie';
import robotAD from '../lotties/robotface.json';
import redgraphAD from '../lotties/redgraph.json';
import moneyAD from '../lotties/loopmoney.json';
import pizzaAD from '../lotties/pizza.json';
import wendAD from '../lotties/wend.json';

export function Onboarding() {
    const [activeStep, setActiveStep] = useState(1);
    const maxStep = 5;

    function nextStep() {
        setActiveStep(activeStep + 1);
        if ((activeStep + 1) > maxStep) {
            localStorage.setItem("_bb_onboarding_completed", "true");
            window.location.reload();
        }
    }

    const robotOptions = {
        loop: true,
        autoplay: true,
        animationData: robotAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const redgraphOptions = {
        loop: true,
        autoplay: true,
        animationData: redgraphAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const moneyOptions = {
        loop: true,
        autoplay: true,
        animationData: moneyAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const pizzaOptions = {
        loop: true,
        autoplay: true,
        animationData: pizzaAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const wendOptions = {
        loop: true,
        autoplay: true,
        animationData: wendAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    let onboardingCompleted = localStorage.getItem('_bb_onboarding_completed');
    if (onboardingCompleted === true || onboardingCompleted === "true") {
        return <div></div>;
    }
    else {
        return (
            <div className='onboarding_crisper'>
                <div className='onboarding_smile'>
                    <Lottie
                        options={activeStep === 1 ? moneyOptions : activeStep === 2 ? robotOptions : activeStep === 3 ? redgraphOptions : activeStep === 4 ? pizzaOptions : wendOptions }
                        height={256}
                        width={256}
                    />
                </div>
                <div className="onboarding_steps">{activeStep} of {maxStep}</div>
                <div className="onboarding_title">
                    {activeStep === 1 && "Looks Like Money"}
                    {activeStep === 2 && "Trading Bro"}
                    {activeStep === 3 && "Ring, Ring"}
                    {activeStep === 4 && "Secret Sauce"}
                    {activeStep === 5 && "Legendary Rarity"}
                </div>
                <div className="onboarding_message">
                    {activeStep === 1 && "Meet the new kid on the block. Hot-Ass Trading Bot."}
                    {activeStep === 2 && "Busy flipping bags and stacking gains. Even when you're sleeping."}
                    {activeStep === 3 && "200 IQ calls and no-brain degen moves are here."}
                    {activeStep === 4 && "Customize strategy like the toppings on your pizza. Extra pepperoni, anyone?"}
                    {activeStep === 5 && "First Trading Bot backed by Wen Dizel."}
                </div>
                <div className="pdside16"><div onClick={() => nextStep()} className="default_next_btn">Next</div></div>
            </div>
        );
    }

}
