import { authFetch } from "./responseHandler";

export class InfoApiService {
    static baseUrl = process.env.REACT_APP_API_URL;

    static async GetExternalWalletBalances(address, needTon, forBurn) {
        const accessToken = localStorage.getItem('bb_token');
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const result = await authFetch(this.baseUrl + `Info/GetExternalWalletBalances?address=${address}&needTon=${needTon}&forBurn=${forBurn}`, { headers });

        return await result.json()
    }

    static async TrackBurn(requestData) {
        const accessToken = localStorage.getItem('bb_token');
        if (!accessToken)
            return;

        const headers = { 'Authorization': 'Bearer ' + accessToken, 'content-type': 'application/json' };

        let request = await fetch(this.baseUrl + `Info/TrackBurn`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestData)
        });

        return request;
    }

    static async TrackTransfer(requestData) {
        const accessToken = localStorage.getItem('bb_token');
        if (!accessToken)
            return;

        const headers = { 'Authorization': 'Bearer ' + accessToken, 'content-type': 'application/json' };

        let request = await fetch(this.baseUrl + `Info/TrackTransfer`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestData)
        });

        return request;
    }

    static async RequestWithdraw(amount, coinAddress) {
        if (!amount || amount === '')
            return;

        const accessToken = localStorage.getItem('bb_token');
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const result = await authFetch(this.baseUrl + `Info/RequestWithdraw?amount=${amount}&coinAddress=${coinAddress}`, { headers, method: 'POST' });

        let isSuccess = result.status === 200;
        let message = result.status === 200 || result.status === 400 ? await result.text() : "Failed";

        return { isSuccess, message: message === "" ? null : message };
    }


    static async GetSpinPoolJettonWallet(address) {
        const accessToken = localStorage.getItem('bb_token');
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const result = await authFetch(this.baseUrl + `Info/GetSpinPoolJettonWallet?coinAddress=${address}`, { headers });

        return await result.json()
    }

    static async GetSpinBalances() {
        const accessToken = localStorage.getItem('bb_token');
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const result = await authFetch(this.baseUrl + `Info/GetSpinBalances`, { headers });

        return await result.json()
    }

    static async GetJettonPageInfo(address) {
        const accessToken = localStorage.getItem('bb_token');
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const result = await authFetch(this.baseUrl + `Info/GetJettonPageInfo?jettonAddress=${address}`, { headers });

        return await result.json()
    }

}