
import React, { useState, useEffect } from 'react';
import { ProfileApiService } from '../services/profile.api.service';
import Lottie from 'react-lottie';
import loadingAD from '../lotties/loading.json';
import { toast, Bounce } from 'react-toastify';
import { UiService } from '../services/ui.service';

export const SeedPopup = ({ children, shown, close }) => {
    const [words, setWords] = useState([]);

    useEffect(() => {
        ProfileApiService.GetSeedPhrase().then(result => {
            setWords(result.words);
        });
    }, []);


    if (shown === false) {
        return null;
    }
    const loadingOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    function copySeed() {
        navigator.clipboard.writeText(words.join(' '));
        UiService.DisplayCopyToast();
    }

    return (
        <div className='fs_modal'>
            <div className='close_btn' onClick={() => close()}><img src='/images/caret.png' /></div>
            {!words || words.length === 0 &&
                <Lottie
                    options={loadingOptions}
                    height={256}
                    width={256}
                    speed={1.5}
                />}
            {words && words.length > 0 &&
                <>
                    <div className='seed_popup_title'>
                        Seed phrase
                    </div>
                    <div className='seed_popup_text'>Write down these words with their numbers<br /> and keep them in a safe place.</div>
                    <div className='seed_words_crisper'>
                        <ol className='seed_words_list'>
                            {words.map((word, index) => <li key={index}>{word}</li>)}
                        </ol>
                    </div>
                    <div className="settings_share_btn" onClick={() => copySeed()}>Copy</div>
                </>
            }

        </div>
    );
}
