export function formattedNumberShort(value, decimalPlaces = 2) {
    // Проверяем, что входное значение является числом
    if (typeof value !== 'number' || isNaN(value)) {
        return '';
    }

    // Проверяем, что decimalPlaces находится в диапазоне от 1 до 3
    if (decimalPlaces < 1 || decimalPlaces > 3) {
        throw new Error("decimalPlaces must be between 1 and 3");
    }

    if (value === 0) {
        return '0';
    }

    const suffixes = ['', 'K', 'M', 'B'];
    const thresholds = [1, 1e3, 1e6, 1e9];

    let index = thresholds.findIndex(threshold => value < threshold * 1000) - 1;

    if (index < 0) {
        index = suffixes.length - 1; // Используем 'B'
    }

    let formattedValue = value / thresholds[index];

    // Если значение больше 1000 для K или M, увеличиваем индекс
    if (formattedValue >= 1000) {
        formattedValue /= 1000;
        index++;
    }

    // Форматируем значение: убираем .00 для целых чисел
    return `${formattedValue % 1 === 0 ? formattedValue.toFixed(0) : formattedValue.toFixed(decimalPlaces)}${suffixes[index]}`;
}