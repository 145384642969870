export const handleApiResponse = async (response) => {
    const excludedUrls = ['GetSpinBalances', 'GetAllWalletsBalance'];

    // Проверяем, если статус 401
    if (response.status === 401) {
        // Получаем URL запроса
        const url = response.url;

        // Проверяем, содержит ли URL одно из исключений
        const shouldReload = !excludedUrls.some(excludedUrl => url.includes(excludedUrl));

        if (shouldReload) {
            window.location.reload(); 
        }
        return null; 
    }

    return response; 
};

export const authFetch = async (url, options = {}) => {
    try {
        const response = await fetch(url, options);
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
};