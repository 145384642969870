import React, { useState, useRef, useEffect } from 'react';
import { ProfileApiService } from '../services/profile.api.service';

export function SwapSettings({ children, shown, close, userSlippage, userMevProtection }) {
    const [selectedSlippage, setSelectedSlippage] = useState(userSlippage || 3);
    const [lastSavedSlippage, setLastSavedSlippage] = useState(userSlippage || 3);
    const [mevProtection, setMevProtection] = useState(userMevProtection || false);
    const [lastSavedMevProtection, setLastSavedMevProtection] = useState(userMevProtection || false);
    const [controlsDisabled, setControlsDisabled] = useState(false);
    const customSlippageInput = useRef();

    const minSlippage = 1;
    const maxSlippage = 99;

    // Функция для сброса состояния
    const resetState = () => {
        setSelectedSlippage(lastSavedSlippage);
        setMevProtection(lastSavedMevProtection);
        setControlsDisabled(false);
        if (customSlippageInput.current) {
            const inputValue = customSlippageInput.current.value;
            const parsedValue = parseFloat(inputValue.replace(',', '.'));
            if (!isNaN(parsedValue) && [1, 3, 10].includes(parsedValue)) {
                customSlippageInput.current.value = '';
            }
        }
    };

    // Эффект для установки значений при открытии модального окна
    useEffect(() => {
        if (shown) {
            setSelectedSlippage(lastSavedSlippage);
            setMevProtection(lastSavedMevProtection);
            if (customSlippageInput.current) {
                if (![1, 3, 10].includes(lastSavedSlippage)) {
                    customSlippageInput.current.value = lastSavedSlippage.toString();
                } else {
                    customSlippageInput.current.value = '';
                }
            }
        }
    }, [shown, lastSavedSlippage, lastSavedMevProtection]);

    useEffect(() => {
        if (shown) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [shown]);


    useEffect(() => {
        const handleResize = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);

            // Принудительное обновление макета
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 0);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        window.addEventListener('focusout', handleResize); // Добавляем обработчик для события focusout

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('focusout', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleTouch = () => {
            // Принудительное обновление макета
            window.dispatchEvent(new Event('resize'));
        };

        document.addEventListener('touchstart', handleTouch);

        return () => {
            document.removeEventListener('touchstart', handleTouch);
        };
    }, []);


    useEffect(() => {
        const handleFocusOut = (event) => {
            if (event.target.tagName === 'INPUT') {
                setTimeout(() => {
                    window.scrollTo(0, 0);
                    document.body.scrollTop = 0;
                }, 100);
            }
        };

        document.addEventListener('focusout', handleFocusOut);

        return () => {
            document.removeEventListener('focusout', handleFocusOut);
        };
    }, []);

    function customSlippageChange(value) {
        if (!value && value === '') {
            setSelectedSlippage(3);
            return;
        }

        let numberValue = Number(value.replace(/,/g, '.'));
        if (numberValue < minSlippage) {
            setSelectedSlippage(minSlippage);
            if (customSlippageInput.current)
                customSlippageInput.current.value = minSlippage;
            return;
        }
        if (numberValue > maxSlippage) {
            setSelectedSlippage(maxSlippage);
            if (customSlippageInput.current)
                customSlippageInput.current.value = maxSlippage;
            return;
        }

        setSelectedSlippage(numberValue);
    }

    function removeLeadingZerosRegex(str) {
        if (!str)
            return '';

        return str.replace(/^0+(?=\d)/, '');
    }

    function handleSave() {
        if (controlsDisabled) return;

        setControlsDisabled(true);

        ProfileApiService.UpdateProfile(selectedSlippage, mevProtection)
            .then(() => {
                setLastSavedSlippage(selectedSlippage);
                setLastSavedMevProtection(mevProtection);
                close();
            })
            .catch((error) => {
                console.error('Error saving settings:', error);
            })
            .finally(() => {
                setControlsDisabled(false);
            });
    }

    if (!shown) {
        return null;
    }

    return (
        <div className={'modal_container visible'}>
            <div onClick={() => { resetState(); close() }} className='modal_overlay_half'></div>
            <div className='bottom_stuck_modal half_stuck_modal  modal_scrollable' style={{ minHeight: "80vh"}}>
                <div className='bottom_stuck_modal_content pdside16'>
                    <div className='mtxl'></div>
                    <div className='popup_title '>
                        Advanced settings
                    </div>
                    <div className='mtl h4'>Slippage</div>
                    <div className='popup_text text_left text_xs'>Slippage is the difference between the expected and actual price. If it's too low, your transaction will be canceled. The default is 3%.</div>
                    <div className='badges mtl'>
                        <div onClick={() => { customSlippageInput.current.value = ''; setSelectedSlippage(1) }} className={'slippage_badge' + (selectedSlippage === 1 ? ' slippage_badge_active' : '')}>1%</div>
                        <div onClick={() => { customSlippageInput.current.value = ''; setSelectedSlippage(3) }} className={'slippage_badge' + (selectedSlippage === 3 ? ' slippage_badge_active' : '')}>3%</div>
                        <div onClick={() => { customSlippageInput.current.value = ''; setSelectedSlippage(10) }} className={'slippage_badge' + (selectedSlippage === 10 ? ' slippage_badge_active' : '')}>10%</div>
                    </div>
                    <input
                        type='number'
                        inputMode="numeric" pattern="[0-9]*"
                        ref={customSlippageInput}
                        min={1}
                        max={50}
                        className={'custom_slippage_input ' + (selectedSlippage > 0 && selectedSlippage !== 1 && selectedSlippage !== 3 && selectedSlippage !== 10 ? " custom_slippage_input_active" : "")}
                        placeholder='Custom %'
                        onChange={(event) => customSlippageChange(event.target.value)}
                    />

                    <div className='mtl h4'>MEV Protection</div>
                    <div className='popup_text text_left text_xs'>Turning on MEV Protect hides your swaps from MEV or sandwich attacks.</div>
                    <div className='badges mtl mev-protection'>
                        <div
                            onClick={() => setMevProtection(false)}
                            className={`mev-badge ${!mevProtection ? 'mev-badge-active' : ''}`}
                        >
                            Off
                        </div>
                        <div
                            onClick={() => setMevProtection(true)}
                            className={`mev-badge ${mevProtection ? 'mev-badge-active' : ''}`}
                        >
                            On
                        </div>
                    </div>

                    <button
                        onClick={handleSave}
                        className={`save_button mtl ${controlsDisabled ? 'unclickable' : ''}`}
                        disabled={controlsDisabled}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}
