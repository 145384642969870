import React, { useState, useEffect } from 'react';

export const FormattedNumberInput = ({ value, onChange, className, ...props }) => {
    const [formattedValue, setFormattedValue] = useState('0');

    const formatNumber = (num) => {
        const parts = num.toString().split(/[.,]/);
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts.join('.');
    };

    const handleChange = (event) => {
        const inputValue = event.target.value.replace(/\s/g, '');

        if (/^(\d*[.,]?\d*)$/.test(inputValue)) {
            const normalizedValue = inputValue.replace(',', '.');
            onChange(normalizedValue);
            setFormattedValue(formatNumber(normalizedValue));
        }
    };

    const handleFocus = () => {
        // Убираем "0" при фокусировке, если текущее значение "0"
        if (formattedValue === '0') {
            setFormattedValue('');
        }
    };

    useEffect(() => {

        setFormattedValue(formatNumber(value));
    }, [value]);

    return (
        <input
            type="text"
            inputMode="decimal"
            className={className}
            value={formattedValue}
            onChange={handleChange}
            onFocus={handleFocus}
            {...props}
        />
    );
};

export default FormattedNumberInput;