import { cuttedWallet } from "../components/cuttedWallet";
import Lottie from 'react-lottie';
import loadingAD from '../lotties/loading.json';
import { useUser } from "../components/user";
import { SeedPopup } from "../components/seed-popup";
import React, { useState } from 'react';
import { toast, Bounce } from 'react-toastify';
import { UiService } from "../services/ui.service";
import { SendCoin } from "../components/send-coin";
import { BurnCoin } from "../components/burn-coin";
import fireAD from '../lotties/fire.json';

const Profile = () => {
  const [showSendModal, setShowSendModal] = useState(false);
  const [showBurnModal, setShowBurnModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  window.Telegram.WebApp.BackButton.show();
  const initData = window?.Telegram?.WebApp?.initData;
  const { user, isLoading, isError } = useUser(initData);
  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  if (isLoading || isError) {
    return (
      <div className="page">
        <Lottie
          options={loadingOptions}
          height={256}
          width={256}
          speed={1.5}
        />
      </div>
    );
  }

  function copyWallet() {
    navigator.clipboard.writeText(user.wallet);
    UiService.DisplayCopyToast();
  }

  const fireOptions = {
    loop: true,
    autoplay: true,
    animationData: fireAD,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};


  return (
    <div className="page">
      <div className="settings_crisper">
        <div className="settings_row">
          <div className="settings_row_title">User name</div>
          <div className="settings_row_value">@{user.userName}</div>
        </div>
        <div className="settings_line"></div>
        <div className="settings_row" onClick={() => copyWallet()}>
          <div className="settings_row_title">Your wallet</div>
          <div className="settings_row_value">{cuttedWallet(user.wallet)}</div>
        </div>
      </div>

      <div className="settings_crisper">
 {/*        <div className="settings_row" onClick={() => setShowBurnModal(true)}>
          <div className="settings_row_title"><img src="/images/fire.png" width={24} height={24} />&nbsp;&nbsp;Burn Coins</div>
          <div className="settings_row_value"><img className="caret_right" src="/images/caret.png" /></div>
        </div>
        <div className="settings_line"></div> */}
        <div className="settings_row" onClick={() => setShowSendModal(true)}>
          <div className="settings_row_title"><img src="/images/dollar_banknote.png" width={24} height={24} />&nbsp;&nbsp;Transfer Coins</div>
          <div className="settings_row_value"><img className="caret_right" src="/images/caret.png" /></div>
        </div>
        <div className="settings_line"></div>
        <div className="settings_row" onClick={() => setShowModal(true)}>
          <div className="settings_row_title"><img src="/images/locked.png" width={24} height={24} />&nbsp;&nbsp;Export Seed</div>
          <div className="settings_row_value"><img className="caret_right" src="/images/caret.png" /></div>
        </div>
        <div className="settings_line"></div>
        <div onClick={() => window.open("https://t.me/+gBjsMt1S4_A3YzVi", "_blank")} className="settings_row">
          <div className="settings_row_title"><img src="/images/ballon.png" width={24} height={24} />&nbsp;&nbsp;Support</div>
          <div className="settings_row_value"><img className="caret_right" src="/images/caret.png" /></div>
        </div>
        <div className="settings_line"></div>
        <div onClick={() => window.open("https://t.me/+gBjsMt1S4_A3YzVi", "_blank")} className="settings_row">
          <div className="settings_row_title"><img src="/images/face_kiss.png" width={24} height={24} />&nbsp;&nbsp;Community</div>
          <div className="settings_row_value"><img className="caret_right" src="/images/caret.png" /></div>
        </div>

      </div>

{/*       <div className="settings_coin_balances">
        {user?.allWalletsBalance != null && user?.allWalletsBalance.length > 0 && user?.allWalletsBalance.map((coin, index) =>
          <div className='asset_item_plain' key={index}>
            <img className='asset_item_image' src={coin?.image} />
            <div className='asset_desc'>
              <div className='asset_short_title'>{coin?.shortTitle}</div>
              <div className='asset_title'>{coin?.title}</div>
            </div>
            <div className='asset_balance_desc'>
              <div className='asset_balance'>{coin?.balance ?? 0}</div>

            </div>
          </div>
        )}
      </div> */}

      {/* <div className="settings_crisper_header">YOUR REFERRAL LINK</div> */}
      {/* <div className="settings_crisper mt4" onClick={() => copyRefLink()}>
        <div className="settings_ref_link">
          <div>{user?.refLink ? user.refLink.substring(0, 45) + '...' : ''}</div><img width={24} height={24} src="/images/copy2.png" />
        </div>
        <div className="settings_share_btn">Share</div>
      </div> */}
      <SeedPopup shown={showModal} close={() => {
        setShowModal(false);
      }} />

      <SendCoin minHeight={500} shown={showSendModal} allWalletsBalance={user?.allWalletsBalance} tonBalance={user?.balance} userWallet={user?.wallet} close={() => {
        setShowSendModal(false);
      }} />

      <BurnCoin minHeight={500} shown={showBurnModal} allWalletsBalance={user?.allWalletsBalance} tonBalance={user?.balance} userWallet={user?.wallet} close={() => {
        setShowBurnModal(false);
      }} />


    </div>

  );
};

export default Profile;