import { authFetch } from "./responseHandler";

export class ActionApiService {
    static baseUrl = process.env.REACT_APP_API_URL;

    static async GetSwapInfo(amount, coinAddress, swapDirection) {
        if (!amount || amount === '')
            amount = 0;


        const accessToken = localStorage.getItem('bb_token');
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const result = await authFetch(this.baseUrl + `Action/SwapInfo?amount=${amount}&coinAddress=${coinAddress}&swapDirection=${swapDirection}`, { headers });

        if (result.status !== 200)
            throw new Error("GetSwapInfo failed");

        return result.json();
    }

    static async Spin(amount, coinAddress) {
        if (!amount || amount === '')
            amount = 0;

        const accessToken = localStorage.getItem('bb_token');
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const result = await authFetch(this.baseUrl + `Action/Spin?amount=${amount}&coinAddress=${coinAddress}`, { headers, method: 'POST' });

        let isSuccess = result.status === 200;
        if (isSuccess) {
            let json = await result.json();

            return { isSuccess, data: json };
        }

        let message = result.status === 200 || result.status === 400 ? await result.text() : "Failed";

        return { isSuccess, message: message === "" ? null : message };
    }

    static async Swap(amount, coinAddress, swapDirection, estimateAmount) {
        if (!amount || amount === '')
            amount = 0;

        const accessToken = localStorage.getItem('bb_token');
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const result = await authFetch(this.baseUrl + `Action/Swap?amount=${amount}&coinAddress=${coinAddress}&swapDirection=${swapDirection}&estimateAmount=${estimateAmount}`, { headers, method: 'POST' });

        let isSuccess = result.status === 200;
        let message = result.status === 200 || result.status === 400 ? await result.text() : "Failed";

        return { isSuccess, message: message === "" ? null : message };
    }

    static async Send(amount, coinAddress, receiverAddress) {
        if (!amount || amount === '')
            return;

        const accessToken = localStorage.getItem('bb_token');
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const result = await authFetch(this.baseUrl + `Action/Send?amount=${amount}&coinAddress=${coinAddress}&receiverAddress=${receiverAddress}`, { headers, method: 'POST' });

        let isSuccess = result.status === 200;
        let message = result.status === 200 || result.status === 400 ? await result.text() : "Failed";

        return { isSuccess, message: message === "" ? null : message };
    }

    static async DepositLood(amount, coinAddress) {
        if (!amount || amount === '')
            return;

        const accessToken = localStorage.getItem('bb_token');
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const result = await authFetch(this.baseUrl + `Action/DepositLood?amount=${amount}&coinAddress=${coinAddress}`, { headers, method: 'POST' });

        let isSuccess = result.status === 200;
        let message = result.status === 200 || result.status === 400 ? await result.text() : "Failed";

        return { isSuccess, message: message === "" ? null : message };
    }


    static async Burn(amount, coinAddress) {
        if (!amount || amount === '')
            return;

        const accessToken = localStorage.getItem('bb_token');
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const result = await authFetch(this.baseUrl + `Action/Burn?amount=${amount}&coinAddress=${coinAddress}`, { headers, method: 'POST' });

        let isSuccess = result.status === 200;
        let message = result.status === 200 || result.status === 400 ? await result.text() : "Failed";

        return { isSuccess, message: message === "" ? null : message };
    }


    static async GetAvailableCoins() {
        const accessToken = localStorage.getItem('bb_token');
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const result = await authFetch(this.baseUrl + `Action/GetAvailableCoins`, { headers });

        return result.json();
    }


}